.page-home {
	width: 100%;
	height: 100%;
	padding: 20px;

	.home {
		width: 100%;
		height: 100%;

		.main {
			width: 100%;
			max-width: 1024px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			@include max-width($scr-md) {
				flex-direction: column;
			}

			@include max-width($scr-xs) {
				text-align: center;
			}

			&__content {
				//
				&--form {
					max-width: 450px;
					padding: 0 0 0 50px;

					@include max-width($scr-md) {
						max-width: 100%;
						padding: 0;
					}
				}
			}

			&__logo {
				width: 100%;
				max-width: 450px;

				img {
					width: 100%;
				}

				@include max-width($scr-md) {
					max-width: 350px;
				}

				@include max-width($scr-xs) {
					max-width: 300px;
				}
			}

			&__title {
				color: $c-primary;
				font-size: $text-size-xl;
				font-weight: $text-weight-semibold;
			}

			&__description {
				margin: 0;
				color: $c-dark-grey;
				font-size: $text-size-body;
			}

			&__button-container {
				margin: 40px 0 0;
				display: flex;
				align-items: center;
				flex-direction: column;

				@include max-width($scr-xs) {
					margin: 20px 0 0;
					display: flex;
					justify-content: center;
				}

				&__turnstile {
					margin-bottom: 20px;
				}
			}

			.referral {
			
				margin-top: 30px;
				margin-bottom: 30px;
	
				&__container {
					border-radius: 10px;
					background-color: $c-white;
					box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
				}
	
				&__box {
					padding: 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}
	
				&__wrapper {
					gap: 16px;
					display: flex;
					align-items: center;
				}
	
				&__logo {
					width: 32px;
					height: 32px;
				}
	
				&__label {
					margin: 0;
					color: $c-dark-grey;
					font-size: $text-size-s;
					text-align: center;
				}
	
				&__value {
					//
					.MuiInputBase-input {
						padding: 0;
	
						&::placeholder {
							font-size: $text-size-s;
						}
					}
	
					.MuiOutlinedInput-notchedOutline {
						border: 0 !important;
					}
				}
			}
		}
	}
}
