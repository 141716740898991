.page-motor-type {
	width: 100%;
	height: 100%;
	padding: 20px;

	.home {
		width: 100%;
		height: 100%;

		.main {
			width: 100%;
			max-width: 1024px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			@include max-width($scr-md) {
				flex-direction: column;
			}

			@include max-width($scr-xs) {
				text-align: center;
			}


			&__logo {
				width: 100%;
				max-width: 450px;

				img {
					width: 100%;
				}

				@include max-width($scr-md) {
					max-width: 350px;
				}

				@include max-width($scr-xs) {
					max-width: 300px;
				}
			}


			&__button-container {
				margin: 40px 0 0;
				display: grid;
				grid-template-columns: repeat(2, 1fr); // Two columns
				grid-template-rows: auto auto auto; // Three rows
				gap: 20px;
				min-width: 450px;
			
				.button {
					width: 100%; // All buttons take full width of their grid cell
				}
			
				// Last button (5th item)
				.button:last-child {
					grid-column: 1 / span 2; // Span across both columns
					justify-self: center; // Center the last button
					width: calc(100% - 20px); // Same width as buttons above considering the gap
				}
			
				@include max-width($scr-xs) {
					min-width: unset;
					width: 100%;
					margin: 20px 0 0;
					gap: 10px;
			
					.button {
						width: 100%;
					}
			
					.button:last-child {
						width: 50%; // Same width as other buttons on mobile
					}
				}
			}

			.button {
				// Add any specific button styles here
				// Ensure no right margin on the last button in the container
				margin-right: 10px; // Space between buttons
		
				&:last-child {
					margin-right: 0; // Remove margin for the last button
				}
			}
		}
	}
}
