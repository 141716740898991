.app-button-image {
    max-width: 250px;
    width: 100%;
    padding: 30px 20px;
    border-radius: 50px;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 2px solid $c-teal;
    transition: all 0.3s ease;

    &::before {
		content: '';
		position: absolute;
		top: -1px;
		left: -1px;
		right: -1px;
		bottom: -1px;
		border-radius: 52px;
		background: linear-gradient(45deg, 
		  #00ffd5,
		  #00ffd5,
		  #002bff,
		  #002bff,
		);
		z-index: 1;
		opacity: 0;
		transition: opacity 0.3s ease;
	  }
	
	  &::after {
		content: '';
		position: absolute;
		top: 1px;
		left: 1px;
		right: 1px;
		bottom: 1px;
		background: white;
		border-radius: 48px;
		z-index: 1;
	  }
	
	  &:hover {
		border-color: transparent;
	
		&::before {
		  opacity: 1;
		}
	  }

	  &:disabled {
		border: 2px solid $c-disabled; 
		background-color: $c-disabled;
	
		&::before, &::after {
		  display: none;
		}
	  }
	

	

	&--outline {
		background-color: transparent;

		.app-button__label {
			color: $c-teal;
		}
	}

	&__label {
		margin: 0;
		color: $c-teal;
		font-weight: 700;
		font-size: $text-size-s;
		z-index: 2;
	}

	&__descrip {
		margin: 0;
		color: grey;
		font-weight: $text-weight-normal;
		font-size: $text-size-xs;
		z-index: 2;
	}

    &__image {
        width: 100%;
        height: 100%;
        max-width: 200px; /* Adjust size as needed */
        max-height: 200px; /* Adjust size as needed */
        margin-bottom: 10px;
		z-index: 2;
    }
}