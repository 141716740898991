@keyframes rotate-gradient {
  0% { background-position: 0% 50%; }
  100% { background-position: 200% 50%; }  // Changed from 100% to 200%
}

.file-upload {
    $self: &;
    margin-bottom: 1rem;
    border: 0.1rem solid transparent;
    border-radius: 0.75rem;
    background-image: linear-gradient(white, white), 
                      linear-gradient(45deg, #3700ff, #00ffff, #3700ff);
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-size: 100% 100%, 200% 100%;
    animation: rotate-gradient 8s ease-in-out infinite;
  
    &__header {
      display: flex;
      justify-content: space-between; 
      align-items: center;
      margin-bottom: 0.5rem;
      padding: 1rem 1rem 0rem 1rem;
    }
  
    &__label {
      font-size: $text-size-s;
      font-weight: $text-weight-semibold;
      color: $c-black;
      margin: 0;
    }
  
    &__required {
      color: $c-red;
      margin-left: 0.25rem;
    }
  
    &__input {
      display: none; // Hide the default file input
    }
  
    &__choose-button {
      display: inline-block;
      padding: 0.5rem 1rem;
      font-size: $text-size-s;
      font-weight: $text-weight-regular;
      color: $c-white;
      background-color: $c-blue;
      border: none;
      border-radius: 0.75rem;
      cursor: pointer;
      transition: background-color 0.15s ease-in-out;
  
      &:hover {
        background-color: #3182ce;
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
      }
  
      &--disabled {
        background-color: #a0aec0;
        cursor: not-allowed;
  
        &:hover {
          background-color: #a0aec0;
        }
      }
    }
  
    &__file-list {
      margin-top: 1rem;
      padding: 0rem 1rem 1rem 1rem;
    }
  
    &__file-list-title {
      font-size: $text-size-s;
      font-weight: $text-weight-semibold;
      margin-bottom: 0.5rem;
    }
  
    &__file-items {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  
    &__file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      background-color: #f7fafc;
      border: 1px solid #e2e8f0;
      border-radius: 0.50rem;
      margin-bottom: 0.5rem;
    }
  
    &__file-name {
      font-size: $text-size-s;
      color: $c-dark-grey;
    }
  
    &__remove-button {
      background-color: $c-red;
      color: $c-white;
      border: none;
      padding: 0.25rem 0.5rem;
      border-radius: 0.50rem;
      font-size: 0.75rem;
      cursor: pointer;
      transition: background-color 0.15s ease-in-out;
  
      &:hover {
        background-color: #c53030;
      }
  
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(229, 62, 62, 0.5);
      }
  
      &--disabled {
        background-color: #feb2b2;
        cursor: not-allowed;
  
        &:hover {
          background-color: #feb2b2;
        }
      }
    }
  
    &__error {
      color: $c-red;
      font-size: 0.75rem;
      margin-top: 0.25rem;
    }

    &__sample-files{

      margin-top: 1rem;
      

      &-title {
        font-size: $text-size-s;
        font-weight: $text-weight-semibold;
        color: $c-light-grey;
        padding: 0rem 0rem 0rem 1rem;
      }

      &-item {
        font-size: $text-size-xs;
        font-weight: $text-weight-normal;
        color: $c-light-grey;
        
        &:last-child {
          padding-bottom: 0rem;
        }
      }
    }
  }